// material-ui
import { Card, List, ListItem, ListItemAvatar, ListItemText, Skeleton } from '@mui/material';

// ==============================|| SKELETON - TOTAL INCOME DARK/LIGHT CARD ||============================== //

// styles
// const CardWrapper = styled(MainCard)(() => ({
//     overflow: 'hidden',
//     position: 'relative',
//     height: '200px',
//     width: '200px',
//     border: '1px #eceff1 solid'
// }));

const TotalIncomeCard = () => (
    <Card sx={{ p: 2 }}>
        <List sx={{ py: 0 }}>
            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                <ListItemText
                    sx={{ py: 0 }}
                    primary={<Skeleton variant="text" height={60} width={160} />}
                    // secondary={<Skeleton variant="text" />}
                />
            </ListItem>
        </List>
    </Card>
);

export default TotalIncomeCard;
