import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { gridSpacing } from 'store/constant';

import useAuth from 'hooks/useAuth';

import { SNACKBAR_OPEN } from 'store/actions';
// import useAuth from 'hooks/useAuth';

import FormField from './formFields';

// import { useFormik } from 'formik';
import { isObject } from 'lodash';
import { getYupSchemaFromMetaData } from './yupSchemaCreator';

const field_types = {
  'string': 'text',
  'text': 'textarea',
  'date-time': 'date',
  'number': 'number',
  'integer': 'number',
  'email': 'email',
  'array': 'array',
  'image_url': 'upload_image',
  'boolean': 'boolean' // TODO: should we allow checkbox vs switch types ?
};

const DynamicForm = ({ resource, action, initialState, onHandleClose, hidden=[], }) => {
  const DEBUG = process.env.NODE_ENV === 'development';
  const dispatch = useDispatch();
  const me = useSelector((state) => state.account.me);

  const schema = useSelector((state) => state.schema.schemaData);
  const adminItems = useSelector((state) => state.admin);

  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [fields, setFields] = useState([]);
  const [formState, setFormState] = useState({});

  // const action = !initialState ? 'create' : 'update';

  const { save_item } = useAuth();

  const get_fielddef = (prop_name, prop, initial_state) => {
    const resource_specs = schema.components.schemas[resource];
    let field_type = !!prop.enum ? 'select' : !!prop.format ? field_types[prop.format] : field_types[prop.type];
    if (!!field_types[prop_name]) {
      // try the name itself for some special cases
      field_type = field_types[prop_name];
    }
    const field_def = {
      field: prop_name,
      label: prop_name, // TODO: translate ?
      type: field_type,
      validationType: prop.type, //field_type === 'text' || field_type === 'select' || field_type === 'email' ? 'string' : field_type,
      validations: [],
      readOnly: prop.readOnly,
      enum: prop_name !== 'userLevel' ? prop.enum : prop.enum.slice(0, me.userLevel + 1) // do not allow to create users with more power then me !
    };
    if (field_type === 'array') {
      field_def.subfields = [];
      for (const sub_prop_name in prop.items.properties) {
        const init_state = !!initialState && initialState[prop_name][0] ? initialState[prop_name][0] : null;
        field_def.subfields.push({ ...get_fielddef(sub_prop_name, prop.items.properties[sub_prop_name], init_state), parent: prop_name, index: 0 });
      }
      field_def.default = [];
    }
    if (field_type === 'email') {
      field_def.validations.push({
        type: "email",
        params: [`${prop_name} is needs to be a valid email`]
      });
      prop.default = me.email;
    }
    if (!!prop.description && prop.description.startsWith('must be id of type')) {
      field_def.resource = prop.description.split('"')[1].toLowerCase();
      field_def.label_attr = 'name';
      field_def.key_attr = 'id';

      field_def.type = 'autocomplete';
      field_def.validationType = 'string'; // returns an ID
      const selected_value = !!adminItems[`${field_def.resource}Active`] ? adminItems[`${field_def.resource}Active`] : null;
      if (!!selected_value) {
        // field_def.value = selected_value.id;
        prop.default = selected_value.id;
      }
    }
    if (!!resource_specs.required && resource_specs.required.includes(prop_name)) {
      field_def.required = true;
      field_def.validations.push({
        type: "required",
        params: [`${prop_name} is required`]
      });
    } else {
      field_def.required = false;
    }
    if (!!initial_state && (initial_state[prop_name] !== undefined || initial_state[prop_name] !== null)) {
      if (!!field_def.resource) {
        field_def.value = !!initial_state[prop_name] && initial_state[prop_name].id ? initial_state[prop_name].id : initial_state[prop_name];
      } else {
        const raw_val = initial_state[prop_name];
        if (!!raw_val && !!prop.format && prop.format.startsWith('date')) {
          field_def.value = raw_val.split('T')[0]; //  new Date(raw_val);
        } else {
          field_def.value = raw_val;
        }
      }
    } else {
      if (prop.default !== undefined && prop.default !== null) {
        field_def.value = prop.default;
      } else {
        if (!!field_def.resource) {
          const selected_value = !!adminItems[`${field_def.resource}Selected`] ? adminItems[`${field_def.resource}Selected`] : null;
          if (!!selected_value && !!selected_value.id) {
            field_def.value = selected_value.id;
          }
        }
        else if (prop.type === 'boolean') field_def.value = false;
        else if (prop.type === 'number') field_def.value = null;
        else if (prop.type === 'array') field_def.value = [];
        else field_def.value = ''; // could we do better ?
      };
    }
    // TODO: add more validations from api spec
    field_def.read_only = action === 'delete' || action === 'show' || action ==='edit' && field_def.field === 'login';
    
    if (prop['x-only']) {
      field_def.show_only_if = prop['x-only']
    }
    return field_def;
  }

  // we now build the form def from the swagger spec here
  // TODO: we may want to do this outside this component and pass in the fields
  // in this way we could have more control, and change the generated fields in before rendering the form
  useEffect(() => {
    let newFields = [];
    const initialValues = {};

    if (!!resource && !!schema && !!schema.components && !!schema.components.schemas) {
      const resource_specs = schema.components.schemas[resource];
      // convert specs to field def array ...    
      for (const prop_name in resource_specs.properties) {
        const raw_prop = resource_specs.properties[prop_name];
        // switch between edit and create mode !
        if (raw_prop.deprecated) continue;

        let prop = raw_prop;
        // select the version we need to writing here ...
        if (!!raw_prop.oneOf) {
          prop = raw_prop.oneOf.find(pr => !!pr.writeOnly);
        };
        if (!!prop.readOnly) continue; // skip readonly fields ?
        const field_def = get_fielddef(prop_name, prop, initialState);
        newFields.push(field_def);
        initialValues[prop_name] = field_def.value;
      }
    }

    setFields(newFields);
    setFormState(initialValues);
  }, [schema, resource, initialState]);


  const formSchema = getYupSchemaFromMetaData(fields, [], []);
  const need_to_hide = (fld, state) => {
    
    if( hidden.includes(fld.field)) return true;

    let must_hide = false;
    if (!!fld.show_only_if && isObject(fld.show_only_if)) {
      for (const attr in fld.show_only_if) {
        must_hide = must_hide || state[attr] !== fld.show_only_if[attr];
      }
    }
    return must_hide;
  };

  const check_form_valid = (new_state) => {
    // check of form is complete and valid, using YUP here ?
    try {
      const valid = formSchema.validateSync(new_state, { abortEarly: false });
      setFormValid(true);
      setFormErrors([]);
    } catch (val_error) {
      console.warn(val_error)
      setFormValid(false);
      setFormErrors(val_error.errors);
    }
  }

  const onChangeHandler = async (value, field_def) => {

    let new_val = value;
    if (!!value && !!value.target && !!value.target.value !== undefined) {
      // handle plain change events from simple inputs downstream ...
      switch (field_def.type) {
        case 'number':
          new_val = isNaN(Number(value.target.value)) ? null : Number(value.target.value);
          break;
        case 'boolean':
          new_val = value.target.checked;
          break;
        default:
          new_val = value.target.value;
      }
    };
    if (!!field_def.resource) {
      if (!!value && value[field_def.key_attr] !== undefined) {
        new_val = value[field_def.key_attr];
      }
      // NOTE: this will filter all dependend resources !
      // BETTER NOT DO THIS we get onexpected side effects
      // dispatch({
      //   type: SET_SELECTED_ITEM, // TODO: do we want this for ALL resource types ?
      //   payload: {
      //     resource: field_def.resource,
      //     id: new_val // may be undefined to reset ....
      //   }
      // });
    }
    // const new_state = { ...formState, [field_def.field]: new_val === undefined ? '' : new_val }; // mayby we dont like null values ?
    let new_state;
    if (!!field_def.parent) {
      const updated_value = formState[field_def.parent];
      if (Array.isArray(updated_value)) {
        if (!updated_value[field_def.index]) {
          updated_value[field_def.index] = {};
        }
        updated_value[field_def.index][field_def.field] = new_val;
      }
      new_state = { ...formState, [field_def.parent]: updated_value };
      new_val = updated_value;
    }
    else {
      new_state = { ...formState, [field_def.field]: new_val }; // mayby we dont like null values ?
    }
    // also update the original fields;
    const look_for = !!field_def.parent ? field_def.parent : field_def.field;
    const updateAtIndex = fields.findIndex((field) => field.field === look_for);
    let updatedFields = [...fields];
    updatedFields[updateAtIndex].value = new_val;

    setFields(updatedFields);
    // setFields(...fields, [])
    setFormState(new_state);
    check_form_valid(new_state);

    // auto save image object
    if (field_def.type === 'upload_image') {
      try {
        await saveItem(new_state);
      } catch (error) {
        console.error(error)
      }

    }
  }

  const saveItem = async (new_state) => {
    // NOTE new_state is normally an event !
    try {
      setLoading(true);
      let payload = !!new_state && !new_state.currentTarget ? { ...new_state } : { ...formState }; // formState my be out of date
      for (const attr in payload) {
        const fdef = fields.find(f => f.field === attr);
        if (!!fdef && !!fdef.show_only_if) {
          if (need_to_hide(fdef.show_only_if, formState)) {
            delete payload[attr];
          }
        }
      }
      let res_url = resource;
      if (resource === 'user' && action == 'create') {
        payload.plain_password = `x${Math.random() * 100000}yz`; // is this realy needed ?
        res_url += '?send_mail=true';
      }
      const updated_data_or_error = await save_item(action, res_url, payload, initialState);
      setLoading(false);
      if (updated_data_or_error.error) {
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: <>
            <FormattedMessage id={`forms.errors.${action}`} />
            <FormattedMessage id={resource} />
            {!!formState.name && <><span> "</span><span>{formState.name}</span><span>" </span></>}
            {updated_data_or_error.message && <span> {updated_data_or_error.message} </span>}
            {!updated_data_or_error.message && <span> {updated_data_or_error.error} </span>}
          </>,
          variant: 'alert',
          alertSeverity: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          close: false
        })
      } else {
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: <>
            <FormattedMessage id={resource} /><span> "</span><span>{formState.name}</span><span>" </span>
            <FormattedMessage id={`forms.success.${action}`} />
          </>,
          variant: 'alert',
          alertSeverity: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          close: false
        })
      }
      onHandleClose();
    } catch (save_error) {
      setLoading(false);
      console.warn(save_error);
      dispatch({
        type: SNACKBAR_OPEN,
        open: true,
        message: <>
          <FormattedMessage id={`forms.errors.${action}`} />
          <FormattedMessage id={resource} />
          {!!formState.name && <><span> "</span><span>{formState.name}</span><span>" </span></>}
          <br />{save_error?.data?.error}
          <br />{save_error?.data?.message}
        </>,
        variant: 'alert',
        alertSeverity: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        close: false
      })
    }
  };

  useEffect(() => {
    check_form_valid(initialState);
  }, [initialState]);


  return (
    <Grid container spacing={gridSpacing} mt={1}>
      {DEBUG && <Grid item xs={12}>
        <pre>acion: {action}</pre>
        <pre>{JSON.stringify(hidden, null, 2)}</pre>
        <pre>{JSON.stringify(formState, null, 2)}</pre>
        <pre>{JSON.stringify(formErrors, null, 2)}</pre>
      </Grid>}
      {fields.map((field) => (!field.readOnly &&
        <Grid item xs={12} key={field.field}>
          {/* { JSON.stringify(field) } */}
          {!need_to_hide(field, formState) && <>
            {!!field.subfields ? <>
              <hr />
              {field.subfields.map(sub_field => <FormField key={sub_field.field} config={sub_field} onChange={(newValue) => onChangeHandler(newValue, sub_field)} />)}
              <hr />
              {field.type === 'array' && <Button>add one more</Button>}
            </>
              : <FormField config={field} onChange={(newValue) => onChangeHandler(newValue, field)} />}
          </>}
        </Grid>))}
      <Grid item xs={6}>
        <Button
          variant="contained"
          size="medium"
          color="error"
          onClick={onHandleClose}>
          <FormattedMessage id="cancel" />
        </Button>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: 'right' }}>
        <LoadingButton
          variant="contained"
          size="medium"
          color={action === 'delete' ? 'warning' : 'secondary'}
          loading={loading}
          disabled={action !== 'delete' && !formValid}
          onClick={saveItem}
        ><FormattedMessage id={action.toLowerCase() === 'edit' ? 'save' : action} />
        </LoadingButton>

        {/* <pre>{formValid}</pre> */}
      </Grid>
    </Grid>
  );
};

export default DynamicForm;