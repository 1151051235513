// material-ui
import { Chip, Stack } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';


// ================================|| Chips for stat page ||================================ //

const LocationChips = ({ loc, dev }) => {
    // based on URL we show the chips, not based solely on input. Because we don't reset this after navigating away
    const location = useLocation();
    const [showChipSet, setShowChipSet] = useState([]);
    useEffect(() => {
        if( location.pathname.includes('stats') ){
            if (location.pathname.includes('location')) {
                setShowChipSet(['location']);
            } else {
                setShowChipSet(['location', 'device']);
            }
        } else {
            setShowChipSet([]); // make empty
        }
    }, [location])

    if (!!loc && !dev ) {
        return <Stack direction="row"><Chip sx={{ marginRight: '8px' }} color="primary" label={loc?.name} /></Stack>
    } else {
         return (<Stack direction="row">
            {showChipSet.includes('location') && <Chip component={NavLink} sx={{ marginRight: '8px' }} color="primary" label={dev?.location.name} clickable to={`/dashboard/stats/location/${dev?.location.id}`} />}
            {showChipSet.includes('device') && <Chip color="secondary" label={dev?.name} />}
        </Stack>);
    }
};

export default LocationChips;