// project imports
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import config from '../config';

// ==============================|| SWAGGER ROUTING ||============================== //
const SERVER = config.server;
const API = config.endpoint.admin;

const swagger_url = `${SERVER}${API}/../swagger.json`;

const APIDocsRoutes = {
    path: '/api-docs',
    element: <SwaggerUI url={swagger_url} />,
    children: []
};

export default APIDocsRoutes;
