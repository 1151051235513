// action - state management
import { LOGIN, LOGOUT, REGISTER } from './actions';

export const initialResourceState = {
    me: null,          // null means server is not hit yet!  [] means no data found on server 
    isLoggedIn: false,
    isInitialized: false
};

// ==============================|| ACCOUNT REDUCER ||============================== //

const accountReducer = (state = initialResourceState, action) => {
    switch (action.type) {
        case REGISTER: {
            const { me } = action.payload;
            return {
                ...state,
                me: me
            };
        }
        case LOGIN: {
            const { me } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                me: me
            };
        }
        case LOGOUT: {            
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                me: null
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
