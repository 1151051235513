// action - state management
import config from 'config';
import { SET_LOCALE, SET_STAT_OPTION } from './actions';

export const initialResourceState = {
    locale: config.i18n,
    chartOptions: []
};

// ==============================|| ACCOUNT REDUCER ||============================== //

const storageReducer = (state = initialResourceState, action) => {
    switch (action.type) {
        case SET_LOCALE: {
            const { locale } = action;
            return {
                ...state,
                locale: locale
            };
        }
        case SET_STAT_OPTION: {
            const { chart_id } = action.payload;
            let newOptions = state.chartOptions;

            let activeOption;

            for (let option of newOptions) {
                if (option.chart_id === chart_id) {
                    activeOption = action.payload;
                }
            }

            if (activeOption) {
                // only update specific chart options
                newOptions = newOptions.map((option) => option.chart_id === chart_id ? option = {...option, ...action.payload} : option);
            } else {
                // add it to the chartOptions list because it's new
                newOptions.push(action.payload);
            }
            return {
                ...state,
                chartOptions: newOptions
            }
        }
        default: {
            return { ...state };
        }
    }
};

export default storageReducer;

export const getSingleChartSettings = (id) => (state) => {
    return state.storage.chartOptions.find( item => item.chart_id === id);    
}