/**
 * axios setup to use mock service
 */

import axios from 'axios';
// import memoryDriver from 'localforage-memoryStorageDriver'
// import localforage from 'localforage';

import { setupCache } from 'axios-cache-adapter'
// import { setup } from 'axios-cache-adapter'

// eslint-disable-next-line no-unused-vars
// async function configure () {

//     // Register the custom `memoryDriver` to `localforage`
//     await localforage.defineDriver(memoryDriver)

//     // Create `localforage` instance
//     const forageStore = localforage.createInstance({
//         // List of drivers used
//         driver: [
//         localforage.INDEXEDDB,
//         localforage.LOCALSTORAGE,
//         memoryDriver._driver
//         ],
//         // Prefix all storage keys to prevent conflicts
//         name: 'itrck'
//     });

//     // Create `axios` instance with pre-configured `axios-cache-adapter` using a `localforage` store
//     return setup({
//         // `axios-cache-adapter` options
//         cache: {
//             maxAge: 15 * 60 * 1000,
//             store: forageStore // Pass `localforage` store to `axios-cache-adapter`
//         }
//     })
// };

const axiosServices = axios.create({ 
    adapter: setupCache({
        maxAge: 5 * 60 * 1000
    }).adapter 
});
axiosServices.interceptors.response.use(
    (response) => response,
        (error) => Promise.reject(console.log(error.response && error.response) || {status: 500, data: 'internal server error'})
);


export default axiosServices;


