import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import theme from 'themes';


// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';

import Snackbar from 'ui-component/extended/Snackbar';

// auth provider
import { JWTProvider } from 'contexts/JWTContext';

// firebase for realytime stats

import { getDatabase } from 'firebase/database'; // Firebase v9+
import { getAuth } from 'firebase/auth'; // Firebase v9+
import { DatabaseProvider, AuthProvider, useFirebaseApp } from 'reactfire';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
    // initialize Database and Auth with the normal Firebase SDK functions
    const database = getDatabase(app);
    const auth = getAuth(app);

    return (
        <AuthProvider sdk={auth}>
            <DatabaseProvider sdk={database}>
                <JWTProvider>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme(customization)}>
                            <CssBaseline />
                            <Locales>
                                <NavigationScroll>
                                    <Routes />
                                    <Snackbar />
                                </NavigationScroll>
                            </Locales>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </JWTProvider>
            </DatabaseProvider>
        </AuthProvider>
    );
};

export default App;
