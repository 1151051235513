import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { transform_to_type } from './tableHelpers';
import RefeshIcon from '@mui/icons-material/RefreshTwoTone';
import useAuth from "hooks/useAuth";

// reducer - state management
import { SEARCH_AND_ORDER, SET_ACTIVE_ITEM, LOADING_DATA } from 'store/actions';

import { tableHeadings } from './cells';

// material-ui

import { useTheme } from '@mui/material/styles';
import {
    Box,
    FormControlLabel,
    Switch,
    Button,
    CardContent,
    Grid,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    Skeleton
} from '@mui/material';
import axios from 'utils/axios';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import TableDialog from './TableDialog';
import EnhancedTableHead from './EnhancedTableHead';
import RealTimeMiniMetric from 'ui-component/stats/RealTimeMiniMetric';
import Occupancy  from 'ui-component/stats/Occupancy';

// assets
import SearchIcon from '@mui/icons-material/Search';
import QueryStatsIcon from '@mui/icons-material/QueryStatsTwoTone';
import VisitsInIcon from '@mui/icons-material/Login';
import VisitsOutIcon from '@mui/icons-material/Logout';

import DownloadIcon from '@mui/icons-material/DownloadTwoTone';
import AddIcon from '@mui/icons-material/AddTwoTone';
import SendingIcon from '@mui/icons-material/HourglassTopTwoTone';
import MailIcon from '@mui/icons-material/AttachEmailTwoTone';
import MailedIcon from '@mui/icons-material/MarkEmailReadTwoTone';
import NotMailedIcon from '@mui/icons-material/ErrorOutlineTwoTone';
import EditIcon from '@mui/icons-material/EditTwoTone';
import DeleteIcon from '@mui/icons-material/DeleteForeverTwoTone';

import config from 'config';

const LIVE_DATA_AVAILABLE = ['device', 'location'];

// ==============================|| SuperTable component ||============================== //

const SuperTable = ({ resource }) => {
    
    const MAX_RT_DEVS = 55;
    const theme = useTheme();
    const [show_live, setShowLive] = useState({}); // indexed by resource
    const { refresh_data } = useAuth();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [data_length, set_data_length] = useState(null);
    const intl = useIntl();

    const [open, setOpen] = useState(false);
    const [action, setAction] = useState('edit'); // or 'delete'
    const [rowStatus, setRowStatus] = useState({}); // status indexed by row.id
    const [selectedRow, setSelectedRow] = useState(null);
    const [hiddenFields, setHiddenFields] = useState([]); // array of field to hide in form

    const tableRows = useSelector((state) => state.admin[resource + 'Displayed']); // TODO: better call this store or something more meaningful ?
    const active_search = useSelector((state) => state.admin[resource + 'Search']);
    const resourceOrder = useSelector((state) => state.admin[resource + 'Order'])
    const me = useSelector((state) => state.account.me);
    const loading = useSelector((state) => state.admin.loadingData);
    
    const dispatch = useDispatch();

    const searchPlaceholder = intl.formatMessage({ id: 'search' }); // since we need text and not an HTML element, we have to it like this
    const editAllowed = !!me?.rights && !!me?.rights[resource] && !!me?.rights[resource].update;
    const deleteAllowed = !!me?.rights && !!me?.rights[resource] && !!me?.rights[resource].delete;
    const REPORT_API = config.endpoint.report;

    const handleSearch = (event) => {
        // just to remember it
        dispatch({
            type: SEARCH_AND_ORDER,
            resource: resource,
            searchValue: event?.target.value,
            translatableHeaders: tableHeadings[resource],
            intl: intl
        });
    };
    const handleRefreshData = async (event) => {
        console.debug("refresh data for: ", resource);
        dispatch({
            type: LOADING_DATA,
            payload: true,
        });
        const new_data = await refresh_data(resource);
        console.debug("... done", new_data);
        dispatch({
            type: LOADING_DATA,
            payload: false,
        });
    };

    const showLoader = () => {
        return <TableBody><tr>
            <td colSpan={8}><Box mx={4} my={2}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
            </Box></td></tr></TableBody>
    }

    const handleEditRow = (event, row) => {
        dispatch({
            type: SET_ACTIVE_ITEM,
            payload: {
                resource: resource,
                id: row.id
            }
        });
        setSelectedRow(row);
        setHiddenFields([]);
        if (!!me.rights && !!me.rights[resource] && !!me.rights[resource].update) {
            if( resource === 'location' && !me.rights[resource].create ){
                setHiddenFields(['name','organisation']);
            }
            setAction('edit');
            setOpen(true);
        }
    };

    const handleDeleteRow = (event, row) => {
        dispatch({
            type: SET_ACTIVE_ITEM,
            payload: {
                resource: resource,
                id: row.id
            }
        });
        setSelectedRow(row);
        setHiddenFields([]);
        if (!!me.rights && !!me.rights[resource] && !!me.rights[resource].delete) {
            setAction('delete');
            setOpen(true);
        }
    };

    const handleRequestSort = (event, property) => {
        const sort_cell = tableHeadings[resource].find(cell => cell.id === property);
        const sort_field = sort_cell.value;
        const isAsc = resourceOrder.orderBy === sort_field && resourceOrder.order === 'asc';

        dispatch({
            type: SEARCH_AND_ORDER,
            resource: resource,
            searchValue: event?.target.value ? event?.target.value : active_search, // pass searchvalue, if its non existent use the one in st
            translatableHeaders: tableHeadings[resource],
            order: isAsc ? 'desc' : 'asc',
            orderBy: sort_field,
            intl: intl            
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        if (event?.target.value) setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };
    
    
    const openDownloadDialog = async () => {
        // smart initial state
        const adhoc_report_row = {period: '-', trigger: '-', recipient: 'no@one.example.com', format: 'csv' };
        const adhoc_hidden_fields = ['period', 'trigger', 'recipient', 'max_rows_in_email', 'pauzed' ];
        setHiddenFields(adhoc_hidden_fields);
        setSelectedRow(adhoc_report_row); 
        if (!!me.rights && !!me.rights[resource] && !!me.rights[resource].create) {
            setAction('download');
            setOpen(true);
        }
    }

    const handleOpenDialog = async () => {
        
        setSelectedRow(null); // clear initial state
        setHiddenFields([]);

        if (!!me.rights && !!me.rights[resource] && !!me.rights[resource].create) {
            setAction('create');
            setOpen(true);
        }
    }

    const handleCloseDialog = (value) => {
        setOpen(false);
    };

    const send_test_mail = async (report) => {
        try {
            // TODO: this mail seems to work without auth token ??
            const target_url = `${REPORT_API}/report_email`;
            const success = await axios.post(target_url, report);
            return success;
        } catch (err) {
            console.error(err);
            return null;
        }
    };

    const handleTestMailClick = async (row) => {
        const updated = { ...rowStatus };
        updated[row.id] = 'sending';
        setRowStatus(updated);
        delete row.location.devs;
        const success = await send_test_mail(row);
        const updated2 = { ...rowStatus };
        updated2[row.id] = !!success ? 'sent' : 'failed';
        setRowStatus(updated2);
    };


    return (
        <>
            <TableDialog open={open} action={action} onClose={handleCloseDialog} type={resource} item={selectedRow} hidden={hiddenFields} />
            <MainCard title={<>
                {!loading && tableRows.length > 0}
                {!!loading && <FormattedMessage id="loading" />}
                &nbsp;<span style={{ textTransform: 'lowercase' }}><FormattedMessage id={resource + 's'} /></span>&nbsp;
                {LIVE_DATA_AVAILABLE.includes(resource) && <span style={{ marginLeft: "20px", paddingTop: '5px'}}>
                    <FormControlLabel control={
                        <Switch onChange={()=>setShowLive({...show_live, [resource]: !show_live[resource]})} checked={!!show_live[resource]} />}
                        label={<FormattedMessage id='show_real_time_data'/>} 
                    />
                </span>}
                {!!loading && <>&hellip;</>}
                <Button style={{float: 'right'}} disabled={loading}
                                onClick={handleRefreshData} 
                                variant="outlined" color="secondary" endIcon={<RefeshIcon />}>
                    {!loading ? <FormattedMessage id='refresh'/> : <FormattedMessage id='please_wait'/>}
                </Button>
            </>}
                content={false}>
                <CardContent>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon fontSize="small" />
                                        </InputAdornment>
                                    )
                                }}
                                value={active_search}
                                onChange={handleSearch}
                                placeholder={searchPlaceholder}
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" justifyContent="flex-end">
                                {!!me && !!me.rights && !!me.rights[resource] && !!me.rights[resource].create &&
                                    <>
                                        <Button
                                            variant="contained"
                                            size="medium"
                                            color="secondary"
                                            onClick={handleOpenDialog}
                                            startIcon={<AddIcon />}
                                        >
                                            <FormattedMessage id="add" />
                                        </Button>
                                        {resource === 'reporting' && <Button
                                            sx={{marginLeft: "1.5em"}}
                                            variant="contained"
                                            size="medium"
                                            color="primary"
                                            onClick={openDownloadDialog}
                                            startIcon={<DownloadIcon />}
                                        >
                                            <FormattedMessage id="download" />
                                        </Button>}
                                    </>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>

                {/* table */}
                <TableContainer>
                    <Table aria-labelledby="table">
                        <EnhancedTableHead
                            order={resourceOrder.order}
                            orderBy={resourceOrder.orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={tableRows?.length ? tableRows?.length : null}
                            resource={resource}
                            editAllowed={editAllowed}
                            deleteAllowed={deleteAllowed}
                            showLive={show_live[resource]}
                        />
                        {loading ? showLoader() :
                            tableRows.length > 0 && <TableBody>
                                { tableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        /** Make sure no display bugs if row isn't an OrderData object */
                                        if (typeof row === 'number') return null;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                selected={row.id === tableRows[resource + 'Selected']?.id}
                                            >
                                                {
                                                    !!resource && tableHeadings[resource].map((cell) => (
                                                        <TableCell
                                                            component="th"
                                                            id={cell.id}
                                                            key={cell.id}
                                                            align={cell.align}
                                                            scope="cell"
                                                            // onClick={(event) => handleClick(event, row)}
                                                            sx={{ cursor: 'pointer', maxWidth: '450px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                                            sortDirection={resourceOrder.orderBy === cell.id ? resourceOrder.order : false}
                                                        >
                                                            {cell.type === 'img' ? <img width={'120px'} alt="Selfcheck promo" src={transform_to_type(cell.value, row, cell.type)} /> :
                                                                <Typography variant="caption" sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}>
                                                                    {transform_to_type(cell.value, row, cell.type)}
                                                                </Typography>}
                                                        </TableCell>
                                                    ))
                                                }

                                                {LIVE_DATA_AVAILABLE.includes(resource) &&
                                                    <>
                                                        {resource === 'location' && !show_live[resource] && <TableCell align="right" sx={{ width: '200px' }}>
                                                            <Occupancy size="20" horizontal={false} show_rate={true} show_light={true} show_level={true} loc={row.id}/>
                                                        </TableCell>}

                                                        {show_live[resource] && <TableCell align="center" sx={{ width: '200px' }}>
                                                            {row.device_type !== 'selfcheck' ? <Box sx={{  display: 'flex', justifyContent: 'center' }}>
                                                            {tableRows?.length <= MAX_RT_DEVS &&  <>
                                                                <RealTimeMiniMetric
                                                                    dev_or_loc={row}
                                                                    metric={{ id: 'today_enters', colorscheme: 'green' }}
                                                                    type={resource}
                                                                    icon={<VisitsInIcon />}
                                                                    icon_pos="right"
                                                                />                                                            
                                                                <RealTimeMiniMetric
                                                                    type={resource}
                                                                    dev_or_loc={row}
                                                                    icon={<VisitsOutIcon />}
                                                                    icon_pos="left"
                                                                    metric={{ id: 'today_exits', colorscheme: 'red' }}
                                                                />
                                                                </>}
                                                            </Box> : <Box><FormattedMessage id="not-available" /></Box>}
                                                        </TableCell>}
                                                        {row.extra && row.extra.count_enter && <TableCell align="right" sx={{ pr: 3 }}>
                                                            {tableRows?.length <= MAX_RT_DEVS && <RealTimeMiniMetric offset={row.extra.count_enter}
                                                                dev_or_loc={row}
                                                                metric={{ id: 'today_enters', colorscheme: 'green' }}
                                                                type={resource} icon={<VisitsInIcon />}
                                                            />}
                                                        </TableCell>}

                                                        {!!row.stats_link && <TableCell align="right" sx={{ pr: 3 }}>
                                                            <Tooltip title={<FormattedMessage id='show-stats'/>}>
                                                                <IconButton color="secondary" size="large" component={Link} to={row.stats_link}>
                                                                    <QueryStatsIcon sx={{ fontSize: '1.3rem' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>}
                                                    </>
                                                }
                                                {editAllowed && <TableCell align="right">
                                                    <Tooltip title={<FormattedMessage id='Edit' />}>
                                                        <IconButton color="secondary" size="large" onClick={(event) => handleEditRow(event, row)}>
                                                            <EditIcon sx={{ fontSize: '1.3rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                }
                                                {deleteAllowed && <TableCell align="right">
                                                    <Tooltip title={<FormattedMessage id='delete' />}>
                                                        <IconButton color="secondary" size="large" onClick={(event) => handleDeleteRow(event, row)}>
                                                            <DeleteIcon sx={{ fontSize: '1.3rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                }
                                                {resource === 'reporting' &&
                                                    <TableCell align="center" sx={{ pr: 3 }}>
                                                        <Tooltip title={<FormattedMessage id="send-test-mail" />}>
                                                            <IconButton color="secondary" size="large"
                                                                disabled={!!rowStatus[row.id] && rowStatus[row.id] === 'sending'}
                                                                onClick={() => { handleTestMailClick(row) }}>
                                                                {!!rowStatus[row.id] && rowStatus[row.id] === 'sending' && <SendingIcon sx={{ fontSize: '1.3rem' }} />}
                                                                {!!rowStatus[row.id] && rowStatus[row.id] === 'sent' && <MailedIcon sx={{ fontSize: '1.3rem' }} />}
                                                                {!!rowStatus[row.id] && rowStatus[row.id] === 'failed' && <NotMailedIcon sx={{ fontSize: '1.3rem' }} />}
                                                                {!rowStatus[row.id] && <MailIcon sx={{ fontSize: '1.3rem' }} />}
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>}
                    </Table>
                </TableContainer>

                {/* table pagination */}
                <TablePagination
                    labelRowsPerPage={<FormattedMessage id="rows-per-page"/>}
                    rowsPerPageOptions={[15, 30, 50, 100, 250, 500]}
                    component="div"
                    count={tableRows?.length ? tableRows?.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </MainCard>
          
        </>
    );
};

export default SuperTable;


