const get_last_numeric_key  = (obj) => {
    return Object.keys(obj).filter(k => !isNaN(Number(k)) ).slice(-1)[0];
}

const get_rt_counts = (dev_stats, year) => {
    if( !dev_stats ) return {};
    
    const last_month_or_week = get_last_numeric_key(dev_stats);
    const last_day = get_last_numeric_key(dev_stats[last_month_or_week]);
    const data_for_today = dev_stats[last_month_or_week]?.[last_day];
    const last_hour = get_last_numeric_key(data_for_today);
    
    // calc last updated time (hour)
    if( data_for_today.M && data_for_today.D ){
        data_for_today.iso_date = new Date(year, data_for_today.M-1, data_for_today.D, last_hour );   
        data_for_today.week = last_month_or_week;
        data_for_today.dow  = last_day;
    } else {
        data_for_today.iso_date = new Date(year, last_month_or_week-1, last_day, last_hour );
        data_for_today.month = last_month_or_week;
        data_for_today.day   = last_day;
    }
    if( data_for_today[last_hour].last_updated ){
        // we have a timesptamp that will be more specific that the complate hour ...
        data_for_today.iso_date = new Date(data_for_today[last_hour].last_updated * 1000)   
    }
    data_for_today.today_enters = data_for_today?.count_enter; 
    data_for_today.today_exits = data_for_today?.count_exit;
    data_for_today.balance = data_for_today.today_enters - data_for_today.today_exits;
    return data_for_today;
}

export { get_rt_counts };
