import { FormattedMessage } from "react-intl";

export const tableHeadings = {  // TODO: use the swager schema for this
    user: [
        {
            id: 'organisation',
            type: 'text',
            label: <FormattedMessage id="organisation" />,
            align: 'left',
            value: 'organisation.name'
        },
        {
            id: 'email',
            type: 'text',
            label: <FormattedMessage id="email" />,
            align: 'left',
            value: 'email',
            defaultSortOrder: 'desc'
        },
        {
            id: 'userLevel',
            type: 'formvalue',
            label: <FormattedMessage id="forms.labels.userLevel" />,
            align: 'left',
            value: 'userLevel'
        },
        {
            id: 'created',
            type: 'date',
            label: <FormattedMessage id="created-at" />,
            align: 'left',
            value: 'created'
        },
    ],
    token: [
        {
            id: 'id',
            type: 'text',
            label: <FormattedMessage id="token" />,
            align: 'left',
            value: 'id'
        },
        {
            id: 'user',
            type: 'text',
            label: <FormattedMessage id="user" />,
            align: 'left',
            value: 'user.email'
        },
        {
            id: 'organisation',
            type: 'text',
            label: <FormattedMessage id="organisation" />,
            align: 'left',
            value: 'organisation.name'
        },
        {
            id: 'is_revoked',
            type: 'boolean',
            label: <FormattedMessage id="forms.labels.is_revoked" />,
            align: 'left',
            value: 'is_revoked'
        },
        {
            id: 'created',
            type: 'date',
            label: <FormattedMessage id="created-at" />,
            align: 'left',
            value: 'created'
        },
    ],
    reporting: [
        {
            id: 'pauzed',
            type: 'boolean',
            label: <FormattedMessage id="forms.labels.pauzed" />,
            align: 'right',
            value: 'pauzed'
        },
        {
            id: 'report',
            type: 'formvalue',
            label: <FormattedMessage id="forms.labels.report" />,
            align: 'left',
            value: 'report'
        },
        {
            id: 'recipient',
            type: 'text',
            label: <FormattedMessage id="email" />,
            align: 'left',
            value: 'recipient',
            defaultSortOrder: 'desc'
        },
        {
            id: 'trigger',
            type: 'formvalue',
            label: <FormattedMessage id="forms.labels.trigger" />,
            align: 'left',
            value: 'trigger'
        },
        {
            id: 'period',
            type: 'formvalue',
            label: <FormattedMessage id="forms.labels.period" />,
            align: 'left',
            value: 'period'
        },
        {
            id: 'interval',
            type: 'formvalue',
            label: <FormattedMessage id="forms.labels.interval" />,
            align: 'left',
            value: 'interval'
        },
        {
            id: 'location',
            type: 'text',
            label: <FormattedMessage id="location" />,
            align: 'left',
            value: 'location.name'
        },
        {
            id: 'device',
            type: 'text',
            label: <FormattedMessage id="device" />,
            align: 'left',
            value: 'device.name'
        },
        {
            id: 'lastsent',
            type: 'datetime',
            label: <FormattedMessage id="forms.labels.lastsent" />,
            align: 'left',
            value: 'lastsent'
        }
    ],
    device: [
        {
            id: 'hidden',
            type: 'boolean',
            label: <FormattedMessage id="hidden" />,
            align: 'right',
            value: 'hidden'
        },
        {
            id: 'name',
            type: 'text',
            label: <FormattedMessage id="name" />,
            align: 'left',
            value: 'name',
            defaultSortOrder: 'desc'
        },
        {
            id: 'login',
            type: 'text',
            label: <FormattedMessage id="Login" />,
            align: 'left',
            value: 'login'
        },
        {
            id: 'device_type',
            type: 'text',
            label: <FormattedMessage id="type" />,
            align: 'left',
            value: 'device_type'
        },
        {
            id: 'location',
            type: 'text',
            label: <FormattedMessage id="location" />,
            align: 'left',
            value: 'location.name'
        },
        {
            id: 'ip',
            type: 'ipaddress',
            label: <FormattedMessage id="ipaddress" />,
            align: 'left',
            value: 'last_known_ip'
        },
    ],
    location: [
        {
            id: 'name',
            type: 'text',
            label: <FormattedMessage id="name" />,
            align: 'left',
            value: 'name',
            defaultSortOrder: 'desc'
        },
        // {
        //     id: 'optimal_balance',
        //     type: 'number',
        //     label: <FormattedMessage id="forms.labels.optimal_balance" />,
        //     align: 'right',
        //     value: 'optimal_balance',
        //     defaultSortOrder: 'desc'
        // },
        // {
        //     id: 'balance_orange',
        //     type: 'number',
        //     label: <FormattedMessage id="forms.labels.balance_orange" />,
        //     align: 'right',
        //     value: 'balance_orange',
        //     defaultSortOrder: 'desc'
        // },
        // {
        //     id: 'balance_red',
        //     type: 'number',
        //     label: <FormattedMessage id="forms.labels.balance_red" />,
        //     align: 'right',
        //     value: 'balance_red',
        //     defaultSortOrder: 'desc'
        // },
    ],
    organisation: [
        {
            id: 'name',
            type: 'text',
            label: <FormattedMessage id="name" />,
            align: 'left',
            value: 'name',
            defaultSortOrder: 'desc'
        },
        {
            id: 'updated',
            type: 'date',
            label: <FormattedMessage id="last-updated" />,
            align: 'left',
            value: 'modifiedOn'
        },
    ],
    promo: [
        {
            id: 'pauzed',
            type: 'boolean',
            label: <FormattedMessage id="forms.labels.pauzed" />,
            align: 'right',
            value: 'pauzed'
        },
        {
            id: 'location',
            type: 'text',
            label: <FormattedMessage id="location" />,
            align: 'left',
            value: 'location.name'
        },
        {
            id: 'order',
            type: 'number',
            label: <FormattedMessage id="forms.labels.order" />,
            align: 'right',
            value: 'order',
            defaultSortOrder: 'desc'
        },
        {
            id: 'name',
            type: 'text',
            label: <FormattedMessage id="name" />,
            align: 'left',
            value: 'name',
            // defaultSortOrder: 'desc'
        },
        {
            id: 'image',
            type: 'img',
            label: <FormattedMessage id="forms.labels.image" />,
            align: 'left',
            value: 'image_url'
        },
        {
            id: 'updated',
            type: 'date',
            label: <FormattedMessage id="last-updated" />,
            align: 'left',
            value: 'modifiedOn'
        },
        {
            id: 'created',
            type: 'date',
            label: <FormattedMessage id="created-at" />,
            align: 'left',
            value: 'created'
        },
    ],
    config: [
        {
            id: 'location',
            type: 'text',
            label: <FormattedMessage id="location" />,
            align: 'left',
            value: 'location.name'
        },
        {
            id: 'is_active',
            type: 'boolean',
            label: <FormattedMessage id="forms.labels.is_active" />,
            align: 'right',
            value: 'is_active'
        },
        {
            id: 'receipt_footer',
            type: 'text',
            label: <FormattedMessage id="forms.labels.receipt_footer" />,
            align: 'left',
            value: 'receipt_footer',
            // defaultSortOrder: 'desc'
        },
        {
            id: 'updated',
            type: 'date',
            label: <FormattedMessage id="last-updated" />,
            align: 'left',
            value: 'modifiedOn'
        },
        {
            id: 'created',
            type: 'date',
            label: <FormattedMessage id="created-at" />,
            align: 'left',
            value: 'created'
        },
    ]


}
