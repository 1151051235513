import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';


// login routing
const AuthResetPassword = Loadable(lazy(() => import('views/pages/authentication/ResetPassword')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
                <MinimalLayout />
        </NavMotion>
    ),
    children: [
        // we allow reset password even if you are logged in !
        {
            path: '/reset-password',
            element: <AuthResetPassword />
        }
    ]
};

export default LoginRoutes;
