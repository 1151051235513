import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { tableHeadings } from './cells';
import {
    Box,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';


// ==============================|| TABLE HEADER ||============================== //

const EnhancedTableHead = ({ order, orderBy, onRequestSort, resource, extraFields, editAllowed, deleteAllowed, showLive }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const [headerCells, setHeaderCells] = useState([]);

    // table header options
    // TODO: remove this when moving to schema based architecture in a seperate component
    const getHeaderCells = (resource) => {
        return tableHeadings[resource];
    }

    useEffect(() => {
        setHeaderCells(getHeaderCells(resource));
    }, [resource]);

    return (
        <TableHead>
            <TableRow>
                {!!resource && headerCells.length > 0 && headerCells.map((cell) => (
                    <TableCell
                        key={cell.id}
                        align={cell.align}
                        padding={cell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === cell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === cell.id}
                            direction={orderBy === cell.id ? order : 'asc'}
                            onClick={createSortHandler(cell.id)}
                        >
                            {cell.label}
                            {orderBy === cell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {(resource === 'device' || resource === 'location') &&
                    <>
                        {resource === 'location' && !showLive && <TableCell sortDirection={false} align="right" sx={{ width: '200px' }}>
                            <FormattedMessage id="occupancy" />
                        </TableCell>}
                        {showLive && <TableCell sortDirection={false} align="center" sx={{ width: '200px' }}>
                            <FormattedMessage id="live_data" />
                        </TableCell>}
                        <TableCell sortDirection={false} align="right" sx={{ pr: 3 }} colSpan={editAllowed ? 1 : 2}>
                            <FormattedMessage id="details" />
                        </TableCell>
                    </>
                }
                {
                    editAllowed && <TableCell sortDirection={false} align="right" sx={{ pr: 3 }} colSpan={1}>
                        <FormattedMessage id="edit" />
                    </TableCell>
                }
                {
                    deleteAllowed && <TableCell sortDirection={false} align="right" sx={{ pr: 3 }} colSpan={1}>
                        <FormattedMessage id="delete" />
                    </TableCell>
                }
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead;