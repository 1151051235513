import { forwardRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
// project imports

// ==============================|| FadeComponent ||============================== //

const FadeInContainer = forwardRef(({ children, visible }, ref) => {
    return (<AnimatePresence>
        {visible &&
            (<motion.div
                initial={{ opacity: 0, scale: 0.96 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0 }}

            >
                {children}
            </motion.div>)}
    </AnimatePresence>)
});

export default FadeInContainer;
