import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from '../config';

// third-party
import { Chance } from 'chance';
import download from 'js-file-download';

// reducer - state management
import { LOADING_DATA, DESTROY_SESSION, LOGIN, LOGOUT, SET_ITEMS_FOR_RESOURCE, ADD_ITEM_TO_RESOURCE, SET_SCHEMA_DATA, SET_LOCALE, SET_RT_VISITS_DATA, UPDATE_ITEM_FOR_RESOURCE } from 'store/actions';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'axios';

// we need a firebase checkable token to get secure connection to the realtime data
import { useFirebaseApp } from 'reactfire';
import { getAuth, signInWithCustomToken } from 'firebase/auth'; // Firebase v9+
// import _ from 'lodash';


const chance = new Chance();

axios.defaults.baseURL = config.server; // this is comon for ALL calls in the client
const AUTH_API = config.endpoint.auth;
const CORE_API = config.endpoint.admin;
const REPORT_API = config.endpoint.report;
const RT_API_ENDPOINT = config.rt_server + config.endpoint.rt;

const DOWNLOAD_BASE = config.server + REPORT_API;

const UPLOAD_API = CORE_API.replace('admin/', 'upload/')
const ADMIN_RESOURCES = ['organisation', 'location', 'device'];
const USER_RESOURCES = ['user', 'reporting', 'token', 'config', 'promo'];
const NS_FOR_DEVTYPE = {
    'eco_gate' : 'ECO',
    'brickstream_people_counter': 'BS',
    'intenta': 'INTENTA'
};

const get_admin_rights = (userLevel) => { // TODO: should be on the server
    let rights = {};

    for (const resource of ADMIN_RESOURCES) {
        rights[resource] = {
            create: userLevel > 4,
            read: userLevel > 0,
            update: userLevel > 4,
            delete: userLevel > 4
        }
    }
    for (const resource of USER_RESOURCES) {
        rights[resource] = {
            create: userLevel > 0,
            read: userLevel > 0,
            update: userLevel > 0,
            delete: userLevel > 0 // ??
        }
    }
    // extra for visit trafic light ...
    rights.location.update = userLevel > 0; // we need 2 or more 
    // rights.location.create = userLevel > 0;
    
    //user management is not for al users 
    rights.user.read   = userLevel > 1;
    rights.user.create = userLevel > 1;
    rights.user.update = userLevel > 1;
    rights.user.delete = userLevel > 1;
    
    
    return rights;
};

const currentYear = new Date().getFullYear();

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {

    // initialize Database and Auth with the normal Firebase SDK functions
    const fb_auth = getAuth(useFirebaseApp());

    const account_state = useSelector((state) => state.account);
    const selected_org = useSelector((state) => state.admin.organisationSelected);
    
    const [loading_specs, SetLoadingSpecs] = useState(false);
    const [loadingData, SetLoadingData] = useState(false);
    const [serviceToken, setServiceToken] = useState(null);

    const dispatch = useDispatch();
    const request_options = {
        headers: { // query URL without using browser cache 
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        },
    }
    
    const get_rt_visits = async (device) => {
        // eco readers will use the regulat stats from the week stats we also use in themini charts etc
        if( device?.device_type?.startsWith("eco") ) {
            dispatch({
                type: SET_RT_VISITS_DATA,
                dev_id: device.id,
                loading: true,
                error: false
            });
            const stats_id = `stats/device/week/${device.id}/${currentYear}`;
            const base_stats = await refresh_data(stats_id);
            if( !base_stats ) {
                dispatch({
                    type: SET_RT_VISITS_DATA,
                    dev_id: device.id,
                    loading: false, // make sure we reset the loading state 
                    error: true,
                    payload: null
                });
            }
            return base_stats;
        }
        try {
            const dev_id = device.id;
            const dev_rt_id = device.login;
            const NS = NS_FOR_DEVTYPE[device.device_type];
            const rnd = Math.floor( Math.random() * 100000 );
            let target_url = `${RT_API_ENDPOINT}/${NS}/visits/${dev_rt_id}/${device.rt_hash}?r=${rnd}`;
            // let target_url = `${RT_API_ENDPOINT}/BS/visits/rot-cen-ingang5`;
            
            dispatch({
                type: SET_RT_VISITS_DATA,
                dev_id: dev_id,
                loading: true,
                error: false
            });
            console.debug(`start update RT data at url:  ${target_url}`);
            const items = await axios.get(target_url, request_options);
            console.debug(`updated RT data seen:  ${items?.data.iso_date} / ${items?.data.modifiedOn}`);
            
            if (!!items && !!items.data) {
                dispatch({
                    type: SET_RT_VISITS_DATA,
                    dev_id: dev_id,
                    loading: false,
                    error: false,
                    payload: items.data
                });
                return items.data;
            }
        } catch (err) {
            console.warn(err);
            dispatch({
                type: SET_RT_VISITS_DATA,
                dev_id: device.id,
                loading: false, // make sure we reset the loading state 
                error: true,
                payload: null
            });
            return null;
        }
    };

    const refresh_data = async (resource) => {
        try {
            let target_url = `${CORE_API}/${resource}`;

            if (resource === 'device') {
                const extra_query = `extra=&extra=update_loc_rt_ids`; // MUST be an array ,,.
                target_url += `?${extra_query}`;
            };
            const items = await axios.get(target_url, request_options);

            if (!!items && !!items.data) {
                dispatch({
                    type: SET_ITEMS_FOR_RESOURCE,
                    resource: resource,
                    payload: items.data
                });
                return items.data;
            }
        } catch (err) {
            console.error(err);
            return null;
        }
    };

    const save_item = async (action, resource, formState, initialState) => {
        if( resource === 'reporting' && action === 'download' ){
            try {
                const report_params = formState;
                const report_data = await axios.post(`${REPORT_API}/report_id`, report_params);
                const download_link = `${DOWNLOAD_BASE}/${report_data.data.id}.${report_params.format}`;
                const report_filename = `report_${report_params.location}_${report_params.device}_from_${report_params.start_date}_to_${report_params.end_date}.${report_params.format}`;
                // THIS call SHOULD be allowed without a valid token ...
                const report_file = await axios.get(download_link, { responseType: 'blob' });
                download(report_file.data, report_filename);
                return {success: true, link: download_link};
                
            } catch (err) {
                console.error(err);   
                return {error: err}; 
            };
        };
        try {
            let item = {
                type: undefined, // will also update existing items ...
                resource: resource.split('?')[0],
                payload: undefined
            };

            let updated_data;

            if (action === 'create') {
                const new_item_resp = await axios.post(`${CORE_API}/${resource}`, formState);
                updated_data = { ...new_item_resp.data, _action: action };
                item.type = ADD_ITEM_TO_RESOURCE; item.payload = updated_data;

            } else {
                if (action === 'delete') {
                    await axios.delete(`${CORE_API}/${resource}/${initialState.id}`);
                    updated_data = { ...initialState, _action: action };
                    item.type = ADD_ITEM_TO_RESOURCE; item.payload = updated_data;
                } else {
                    const updated_item_resp = await axios.put(`${CORE_API}/${resource}/${initialState.id}`, formState);
                    updated_data = { ...updated_item_resp.data, _action: action };
                    formState?.balance_red ? item.type = UPDATE_ITEM_FOR_RESOURCE : item.type = ADD_ITEM_TO_RESOURCE;
                    
                    item.payload = updated_data;
                }
            }
            try {
                dispatch(item); // dispatch the item, add or update it
            } catch (update_error) {
                console.warn(update_error);
            }
            return updated_data;
        } catch (save_error) {
            console.warn(save_error);
            if( save_error?.response?.data ){
                return save_error.response.data;
            } else {
                return {error: 'internal server error', message: `unhandled error for ${action} on ${resource}`}
            }
        }
    };

    const concurrent_requests = async (endpoints) => {
        const axiosArray = endpoints.map((ep) => axios.get(`${CORE_API}/${ep}`));
        try {
            const results = await Promise.allSettled(axiosArray);
            for (const result of results) {
                if (result.status === 'fulfilled') {
                    dispatch({
                        type: SET_ITEMS_FOR_RESOURCE,
                        resource: result.value.config.url.split('EKZ/')[1].split('?')[0], // fix this differently
                        payload: result.value.data
                    });
                } else {
                    // this means a rejection (no other status available)
                    console.error(result.reason)
                }
            }
            // done looping through results. Stop loading state
            dispatch({
                type: LOADING_DATA,
                payload: loadingData
            })
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const load_specs = async () => {
            // load schema info from remote swagger api spec
            SetLoadingSpecs(true);
            try {
                const response = await axios.get(`${CORE_API}/../swagger.json`);
                const schema_data = response.data;
                dispatch({
                    type: SET_SCHEMA_DATA,
                    payload: { schemaData: schema_data }
                });
            } catch (load_schema_error) {
                console.warn(load_schema_error);
            };
            SetLoadingSpecs(true);
        };
        if (!loading_specs) load_specs();
    });

    useEffect(() => {
        const load_data = async () => {
            SetLoadingData(true);
            // NOTE: order is important!
            await concurrent_requests([...ADMIN_RESOURCES, ...USER_RESOURCES]);
            SetLoadingData(false);
        };
        if (!!serviceToken && !loadingData) load_data();
    }, [serviceToken]);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                // const fbToken = window.localStorage.getItem('fbToken');

                if (serviceToken) {
                    await setSession(serviceToken, fb_auth);
                    try {
                        const response = await axios.get(`${CORE_API}/user/me`);
                        const me = response.data;
                        // if (!me.rights) me.rights = get_admin_rights(me.userLevel);
                        me.rights = get_admin_rights(me.userLevel);

                        if (!me.preferences) {
                            me.preferences = { first_visit: true }; // just for testing we domnt override prefs
                        } else {
                            me.preferences.first_visit = false;
                        };
                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                me: me
                            }
                        });
                    } catch (e) {
                        console.warn(e);
                        // token has expired !?
                        dispatch({ type: LOGOUT });
                        dispatch({ type: DESTROY_SESSION });
                    }

                } else {
                    dispatch({ type: LOGOUT });
                }
            } catch (err) {
                console.warn(err);
                dispatch({ type: LOGOUT });
                dispatch({ type: DESTROY_SESSION });
            }
        };
        init();
    }, [dispatch, fb_auth]);


    const setSession = async (serviceToken, auth) => {
        if (serviceToken) {
            localStorage.setItem('serviceToken', serviceToken);

            axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
            try {
                const fb_user = await signInWithCustomToken(auth, serviceToken);
                setServiceToken(serviceToken); // als keep this in the state
            } catch (fb_error) {
                console.warn(fb_error);
                localStorage.removeItem('serviceToken');
                delete axios.defaults.headers.common.Authorization;
                setServiceToken(null);
                dispatch({ type: LOGOUT });
                dispatch({ type: DESTROY_SESSION });
            }
        } else {
            // logout(auth); // do we need to logout on firebase also ?
            localStorage.removeItem('serviceToken');
            delete axios.defaults.headers.common.Authorization;
            setServiceToken(null);
            dispatch({ type: LOGOUT });
            dispatch({ type: DESTROY_SESSION });
        }
    };

    const refresh_api_token = async () => {
        try {
            const response = await axios.get(`${CORE_API}/user/me?refresh=api_token`);
            const me = response.data;
            me.rights = get_admin_rights(me.userLevel);
            await refresh_data('token');
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    me: me
                }
            });
        } catch (error) {
            console.warn(error);
            // TODO: should we logout here !?
        }
    }

    // const refresh_user_prefs = async () => {
    //     try {
    //         const response = await axios.get(`${CORE_API}/user/me?refresh=1`);
    //         const me = response.data;
    //         if (!me.rights) me.rights = get_admin_rights(me.userLevel);
    //     } catch (error) {
    //         console.warn(error);
    //         // TODO: should we logout here !?
    //     }
    // }

    const login = async (email, password) => {
        const response = await axios.post(`${AUTH_API}/login`, { email: email, password: password });

        const me = response.data;
        me.rights = get_admin_rights(me.userLevel);
        const serviceToken = me.auth;
        await setSession(serviceToken, fb_auth);

        // const token_resp = await axios.get(`${CORE_API}/token`);
        // const api_tokens = token_resp.data;
        // me.api_tokens = api_tokens;

        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                me: me
            }
        });
        if (me?.preferences?.preferred_lang) {
            dispatch({ type: SET_LOCALE, locale: me.preferences.preferred_lang });
        }
    };

    const register = async (email, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post(`${AUTH_API}/register`, {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;  // TODO: why users plural ?

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null, fb_auth);
        dispatch({ type: SET_LOCALE, locale: 'nl' });
        dispatch({ type: LOGOUT });
        dispatch({ type: DESTROY_SESSION });
    };

    const forgotPassword = async (email) => { // TODO: should we support username also ?
        await axios.post(`${AUTH_API}/forgot`, { email: email }); // wil raise en exception if not 200 OK this is handled by the forgot component
        // const { serviceToken, user } = response.data;
        // const user = response.data;
        // TODO: should we create an FORGOT action (?)
        // dispatch({
        //     type: FORGOT,
        //     payload: {
        //         email_success: user.transaction
        //     }
        // });
        return true; // do we need this ?
    };
    const timeout = (delay) => {
        return new Promise(res => setTimeout(res, delay));
    };

    const welcomeUser = async (email) => { // TODO: should we support username also ?
        // THIS WILL SEND OUT THE WELCOME EMAIL ...
        let success = false;
        for (let i = 1; !success && i < 25; i++) {
            try {
                await axios.post(`${AUTH_API}/welcome`, { email: email }); // wil raise en exception if not 200 OK this is handled by the forgot component     
                success = true;
            } catch (error) {
                console.warn(error);
                await timeout(1000);
            }
        }
        // const { serviceToken, user } = response.data;
        // const user = response.data;
        // TODO: should we create an FORGOT action (?)
        // dispatch({
        //     type: WELCOME,
        //     payload: {
        //         email_success: user.transaction
        //     }
        // });
        return success; // do we need this ?
    };

    const resetPassword = async (payload) => { // TODO: should we support username also ?
        // the sever just wants to see the uid !
        try {
            delete payload.email;
        } catch (e) { };

        await axios.post(`${AUTH_API}/reset`, payload); // wil raise en exception if not 200 OK this is handled by the forgot component
        // const { serviceToken, user } = response.data;
        // const user = response.data;
        // TODO: should we create an FORGOT action (?)
        // dispatch({
        //     type: FORGOT,
        //     payload: {
        //         email_success: user.transaction
        //     }
        // });
        return true; // do we need this ?
    };

    const updateProfile = async (pref) => {
        const newPrefs = { ...account_state.me.preferences, ...pref }; // dont forget all not changes prefs
        try {
            let response = await axios.put(`${CORE_API}/user/me`, { preferences: newPrefs });
            let me = response.data;
            me.rights = get_admin_rights(me.userLevel);
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    me: me
                }
            })
        } catch (error) {
            console.warn(error);
        }
    };

    const upload_image = async (fileToUpload, override_id) => {

        let formData = new FormData();
        if (!!override_id) formData.append('id', override_id);
        formData.append('organisation',  !!selected_org ? selected_org.name : 'EKZ');
        formData.append('files', fileToUpload); // TODO: should we name it file ?
        // filesToUpload.forEach((file) => formData.append("files", file));
        try {
            const response = await axios.post(`${UPLOAD_API}/image`, formData);
            console.log(response.data); // upload may handle more files on one post
            return response.data[0];
        } catch (error) {
            console.warn(error);
            return null;
        }
    };


    if (account_state.isInitialized !== undefined && !account_state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...account_state, get_rt_visits, refresh_data, save_item, upload_image, login, logout, register, refresh_api_token, welcomeUser, forgotPassword, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default JWTContext;
