// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';

import { IconUser, IconHome, IconDeviceLaptop, IconDashboard, IconFileReport, IconCheckbox, IconAccessPoint, IconPrinter, IconTools, IconReceipt } from '@tabler/icons';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';

const menuItems = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                url: '/dashboard',
                requiredUserLevel: 1,
                icon: IconDashboard,
                children: []
            },
        ]
    },
    {
        id: 'admin',
        title: 'Menu',
        type: 'group',
        children: [
            {
                id: 'organisations',
                resource: 'organisation',
                title: 'organisations',
                translatedId: 'organisations',
                type: 'item',
                url: '/admin/organisations',
                icon: LocalLibraryIcon,
                endpoint: 'organisation',
                requiredUserLevel: 4,
                children: []
            },
            {
                id: 'locations',
                resource: 'location',
                title: 'locations',
                translatedId: 'locations',
                type: 'item',
                url: '/admin/locations',
                icon: IconHome,
                endpoint: 'location',
                children: [],
                requiredUserLevel: 1,
            },
            {
                id: 'devices',
                resource: 'device',
                title: 'devices',
                translatedId: 'devices',
                type: 'item',
                url: '/admin/devices',
                icon: IconDeviceLaptop,
                endpoint: 'device',
                children: [],
                requiredUserLevel: 1,
            },
            {
                id: 'users',
                resource: 'user',
                title: 'users',
                translatedId: 'users',
                type: 'item',
                url: '/admin/users',
                icon: IconUser,
                endpoint: 'user',
                children: [],
                requiredUserLevel: 2,
            },
            {
                id: 'reportings',
                resource: 'reporting',
                title: 'reportings',
                translatedId: 'reportings',
                type: 'item',
                url: '/admin/reportings',
                icon: IconFileReport,
                endpoint: 'reporting',
                children: [],
                requiredUserLevel: 1,
            },
            {
                id: 'tokens',
                resource: 'token',
                title: 'tokens',
                translatedId: 'tokens',
                type: 'item',
                url: '/admin/tokens',
                icon: IconCheckbox,
                endpoint: 'token',
                children: [],
                requiredUserLevel: 1,
            },

        ]
    }, {
        id: 'slfchck',
        title: 'Selfcheck',
        type: 'group',
        children: [

            {
                id: 'configs',
                resource: 'config',
                title: 'configs',
                translatedId: 'config',
                type: 'item',
                url: '/admin/configs',
                icon: IconReceipt,
                endpoint: 'config',
                children: [],
                requiredUserLevel: 1,
            },
            {
                id: 'promos',
                resource: 'promo',
                title: 'promos',
                translatedId: 'promos',
                type: 'item',
                url: '/admin/promos',
                icon: IconAccessPoint,
                endpoint: 'promo',
                children: [],
                requiredUserLevel: 1,
            }

        ]
    },
]

export const initialState = {
    isOpen: [], // for active default menu
    isOpenRight: [],
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    outlinedFilled: config.outlinedFilled,
    presetColor: config.presetColor,
    locale: config.i18n,
    rtlLayout: config.rtlLayout,
    opened: true,
    rightDrawerOpened: false,
    menuItems: menuItems
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.MENU_OPEN_RIGHT:
            id = action.id;
            return {
                ...state,
                isOpenRight: [id]
            };
        case actionTypes.ADD_MENU_ITEM:
            let newState = state.adminMenuItems;
            newState.children.unshift(action.payload);
            return {
                ...state,
                adminMenuItems: newState
            };
        case actionTypes.PRESET_COLORS:
            return {
                ...state,
                presetColor: action.presetColor
            };
        case actionTypes.THEME_LOCALE:
            return {
                ...state,
                locale: action.locale
            };
        case actionTypes.THEME_RTL:
            return {
                ...state,
                rtlLayout: action.rtlLayout
            };
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case actionTypes.SET_MENU_RIGHT:
            return {
                ...state,
                opened: action.opened
            };
        case actionTypes.SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case actionTypes.SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };
        case actionTypes.SET_OUTLINED_FILLED:
            return {
                ...state,
                outlinedFilled: action.outlinedFilled
            };
        default:
            return state;
    }
};

export default customizationReducer;
