import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import SuperTable from 'views/admin/tables/SuperTable';

// sample page routing
const DashboardPage = Loadable(lazy(() => import('views/dashboard')));
const AccountPage = Loadable(lazy(() => import('views/account')));
const NotFoundPage = Loadable(lazy(() => import('views/pages/not-found')));
const StatsPage = Loadable(lazy(() => import('views/pages/stats')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardPage />
        },
        {
            path: '/account',
            element: <AccountPage />
        },
        {
            path: '/admin/users',
            element: <SuperTable resource='user' />
        },
        {
            path: '/admin/organisations',
            element: <SuperTable resource='organisation' />
        },
        {
            path: '/admin/locations',
            element: <SuperTable resource='location' />
        },
        {
            path: '/admin/devices',
            element: <SuperTable resource='device' initialsearch='' />
        },
        {
            path: '/admin/reportings',
            element: <SuperTable resource='reporting' initialsearch='' />
        },
        {
            path: '/admin/tokens',
            element: <SuperTable resource='token' initialsearch='' />
        },
        {
            path: '/admin/promos',
            element: <SuperTable resource='promo' initialsearch='' />
        },
        {
            path: '/admin/configs',
            element: <SuperTable resource='config' initialsearch='' />
        },
        {
            path: '/dashboard/stats/:resource/:id',
            element: <StatsPage />
        },
        {
            path: '/dashboard/stats/:resource/:id/:rt_id',
            element: <StatsPage />
        },
        {
            path: '*',
            element: <NotFoundPage />
        }
    ]
};

export default MainRoutes;
