import { useSelector, useDispatch } from 'react-redux';
import { SET_ACTIVE_ITEM, SET_SELECTED_ITEM } from 'store/actions';

// material-ui
import { Box, Typography, Autocomplete, TextField } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { FormattedMessage } from 'react-intl';

// assets
import { IconChevronRight } from '@tabler/icons';
import LocationChips from "ui-component/stats/LocationChips";

// ==============================|| BREADCRUMBS ||============================== //
const separatorIcon = <IconChevronRight stroke={1.5} size="1rem" />;

const Breadcrumbs = () => {

    const adminItems = useSelector((state) => state.admin);
    const dispatch = useDispatch();
    
    const setOrganisation = (value) => {
        // make it BOTH active AND SELECTED !??
        dispatch({
            type: SET_ACTIVE_ITEM,
            payload: {
                resource: 'organisation',
                id: value?.id ? value.id : undefined
            }
        });
        dispatch({
            type: SET_SELECTED_ITEM,
            payload: {
                resource: 'organisation',
                id: value?.id ? value.id : undefined
            }
        });
    }
    const setLocation = (value) => {
        dispatch({
            type: SET_SELECTED_ITEM,
            payload: {
                resource: 'location',
                id: value?.id ? value.id : undefined
            }
        });
    }
    // const reset_location = () => {
    //     dispatch({
    //         type: SET_SELECTED_ITEM,
    //         payload: {
    //             resource: 'location',
    //             id: undefined
    //         }
    //     });
    // };
    return <Box sx={{ p: 2 }}>

        <MuiBreadcrumbs sx={{ '& .MuiBreadcrumbs-separator': { width: 16, ml: 1.25, mr: 1.25 } }} aria-label="breadcrumb" separator={separatorIcon}>
            {!!adminItems.organisationSelected && adminItems?.organisation?.length < 2 && <Typography color="black">{adminItems.organisationSelected.name}</Typography>}
            {!!adminItems?.organisation && adminItems?.organisation.length > 1 &&
                // <FormField onChange={(newValue) => { setOrganisation(newValue) }}
                //     config={{ type: 'autocomplete', field: 'organisation', label: 'organisation', resource: 'organisation', label_attr: 'name', key_attr: 'id' }} />
                // TODO: should use our improved formfield whever we can !? 
                <Autocomplete
                    disablePortal
                    value={adminItems?.organisationSelected ? { label: `${adminItems?.organisationSelected?.name} (${adminItems?.organisationSelected?.id})`, id: adminItems?.organisationSelected?.id } : null}
                    size="small"
                    id="autocomplete-organisation"
                    options={adminItems.organisation.map((org) => { return { label: `${org.name} (${org.id})`, id: org.id } })}
                    sx={{ width: 250 }}
                    onChange={(event, newValue) => { setOrganisation(newValue) }}
                    isOptionEqualToValue={(option, value) => {
                        if (!!option && !!option.id && !!value && !!value.id) {
                            return option.id === value.id;
                        } else {
                            return option === value;
                        }
                    }}
                    renderInput={(params, i) => <TextField key={i} {...params} label={<FormattedMessage id="organisation" />} />}
                />
                
                
            }

            {/* {!!adminItems.locationSelected &&
                <Stack flexDirection="row" alignItems="center">
                    <Typography color="black">{adminItems.locationSelected.name}</Typography>
                    <IconButton color="secondary" size="large" onClick={(event) => reset_location()}>
                        <DeleteIcon sx={{ fontSize: '1.3rem' }} />
                    </IconButton>
                </Stack>

            } */}
                            
                            
            {!!adminItems?.location && adminItems?.location.length > 1000 ? // TODO: do we want a dropdown here or not !?
                // <FormField onChange={(newValue) => { setOrganisation(newValue) }}
                //     config={{ type: 'autocomplete', field: 'organisation', label: 'organisation', resource: 'organisation', label_attr: 'name', key_attr: 'id' }} />
                // TODO: should use our improved formfield whever we can !? 
                <Autocomplete
                    disablePortal
                    size="small"
                    id="autocomplete-organisation"
                    options={adminItems.location.map((loc) => { return { label: `${loc.name} (${loc.id})`, id: loc.id } })}
                    sx={{ width: 250 }}
                    onChange={(event, newValue) => { setLocation(newValue) }}
                    isOptionEqualToValue={(option, value) => {
                        if (!!option && !!option.id && !!value && !!value.id) {
                            return option.id === value.id;
                        } else {
                            return option === value;
                        }
                    }}
                    renderInput={(params, i) => <TextField key={i} {...params} label={<FormattedMessage id="location" />} />}
                />
            :
                <LocationChips loc={adminItems.locationActive} dev={adminItems.deviceActive} />
            }

            {/* {!!adminItems.locationActive && <Typography color="black">{ adminItems.locationActive.name }</Typography>}
            {!!adminItems.deviceActive && <Typography color="black">{ adminItems.deviceActive.name }</Typography> } */}

            {/* {!!adminItems.userSelected && !adminItems.reportingSelected && <Typography color="black">{ adminItems.userSelected.email }</Typography> } */}
            {/* {!!adminItems.reportingSelected && <Typography color="black">{ adminItems.reportingSelected.recipient }</Typography> } */}

        </MuiBreadcrumbs>
    </Box>
};


export default Breadcrumbs;
