
import config from 'config';
import { useState, useEffect } from 'react';
// import TrafficLight from 'react-trafficlight';
import TrafficLight from 'ui-component/trafficlight';
import { useParams } from 'react-router-dom';
import Spinner from 'ui-component/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_ITEM_FOR_RESOURCE } from 'store/actions';

const SERVER = config.server;
const OCC_BASE = `${SERVER}/occupancy/rate`;

const Occupancy = ({ loc, size='auto', show_light=true, show_rate=false, show_level=false, horizontal=false }) => {

    const params = useParams();
    if( params.loc_id ) loc = params.loc_id;

    const dispatch = useDispatch();
    // eslint-disable-next-line eqeqeq
    const occupancy = useSelector( (state) => state?.admin?.location?.find( (l) => l.id == loc)?.occupancy);
    

    useEffect(() => {
        const fetchOcc = async () => {
            
            let updateOccupancy = {
                type: UPDATE_ITEM_FOR_RESOURCE,
                resource: 'location',
                id: loc,
                update_property: 'occupancy',
                payload: { status: 'done', level: 0 } 
            }

            try {
                // eslint-disable-next-line eqeqeq
                const occ_data = await fetch(`${OCC_BASE}/${loc}?refresh=${occupancy?.status == 'need_update'}`);                
                if (occ_data.ok) {
                    const payload = await occ_data.json();                  
                    updateOccupancy.payload = payload; // override payload
                }             
                dispatch(updateOccupancy); // dispatch default object 
            } catch (error) {
                console.warn(error); 
                updateOccupancy.payload = { status: 'error', level: 0, message: error };
                dispatch(updateOccupancy);
            }     
        }

        // if we already fetched, don't do it again. Only if it errored out
        if (!occupancy?.status || ['error', 'need_update'].includes(occupancy?.status)) {
            fetchOcc();
        }
    }, [loc, dispatch, occupancy?.status]);


    if(occupancy?.status === 'loading'){
        return( <Spinner align="end" />);
    }

    const just = show_light && (show_level || show_rate) ? 'end' : 'center';
    return(
         <div style={{display: 'flex', justifyContent: just, padding: '0.2rem'}}>
            <div style={{display: 'flex', justifyContent: 'end', opacity: occupancy?.level > 0 ? 1 : 0.2}}>                                
                {show_rate && occupancy?.rate !== undefined && <div style={{margin: '0.7rem', paddingTop: '0.3rem'}}>{ occupancy?.rate}%</div>}
                {show_level && occupancy?.rate !== undefined && occupancy?.level > 0 && <div style={{margin: '0.7rem', paddingTop: '0.3rem'}}>{ occupancy?.level} / 3</div>}  
                {show_light && occupancy?.level > 0 && <TrafficLight Size={size} Horizontal={horizontal}  RedColor="#ff2400" YellowColor="#ffbf00" GreenColor="#39ff14"  RedOn={occupancy.level === 3} YellowOn={occupancy.level === 2} GreenOn={occupancy.level === 1}/>}
            </div>
        </div>
    );
};

export default Occupancy;