// material-ui
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

// styles
const SpinnerWrapper = styled('div')(({ align }) => ({
    display: 'flex',
    flex: 1,
    height: '100%',
    justifyContent: align,
    alignItems: 'center'
  }));

// ==============================|| LOADER ||============================== //
const Spinner = ({ align = 'center'}) => (
    <SpinnerWrapper align={align}>
        <CircularProgress color="primary" size={30} />
    </SpinnerWrapper>
);

export default Spinner;
