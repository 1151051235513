// material-ui
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

// project imports
import NavGroup from './NavGroup';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {

    const dynamicItems = useSelector((state) => state.customization.menuItems);
    
    
    const navItemsDynamic = dynamicItems.map((item) => {
        switch (item.type) {            
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <div>{navItemsDynamic}</div>;

};

export default MenuList;
