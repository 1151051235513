import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// third-party
import { IntlProvider } from 'react-intl';
import flatten from "flat";
import config from 'config';
import { useSearchParams } from "react-router-dom";
import { SET_LOCALE } from "store/actions";

// load locales files
const loadLocaleData = (locale) => {
    if( config.availableLanguages.includes(locale) ){
        return import(`utils/locales/${locale}.json`);
    } else {
        return import(`utils/locales/en.json`);
    } 
};

// ==============================|| LOCALIZATION ||============================== //

const Locales = ({ children }) => {
    const preferredLang = useSelector((state) => state.storage.locale);
    const [searchParams] = useSearchParams();

    const [messages, setMessages] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        let lang;
        if( searchParams.get('lang') ){
            lang = searchParams.get('lang').toLowerCase();           
        } else {
            if(!!preferredLang && preferredLang.length === 2){
                lang = preferredLang;
            }  
        };
        if( !!lang ){
            loadLocaleData(lang).then((d) => {
                setMessages(d.default);
            }).catch(err => {
                loadLocaleData(preferredLang).then((d) => {
                    setMessages(d.default);
                });   
            });
            dispatch({ type: SET_LOCALE, locale: lang });
        }
    }, [preferredLang, searchParams]);

    return (
        <>
            {messages && (
                <IntlProvider 
                    defaultRichTextElements={{
                        b: (chunks) => <b>{chunks}</b>,
                        u: (chunks) => <u>{chunks}</u>,
                        i: (chunks) => <i>{chunks}</i>,
                        em: (chunks) => <em>{chunks}</em>,
                        strong: (chunks) => <strong>{chunks}</strong>,
                        br: () => <br />,
                        p: (chunks) => <p>{chunks}</p>
                    }}
                    locale={preferredLang} 
                    defaultLocale="nl" 
                    messages={flatten(messages)} onError={(err) => {
                    if (err.code === "MISSING_TRANSLATION") {
                        // console.warn("Missing translation", err.message);
                        return;
                    }
                    throw err;
                }}>
                    {children}
                </IntlProvider>
            )}
        </>
    );
};

Locales.propTypes = {
    children: PropTypes.node
};

export default Locales;
