// action - state management
import { SET_SCHEMA_DATA } from './actions';

export const initialResourceState = {
    schemaData: {}
};

// ==============================|| ACCOUNT REDUCER ||============================== //

const schemaReducer = (state = initialResourceState, action) => {
    switch (action.type) {
        case SET_SCHEMA_DATA: {
            const { schemaData } = action.payload;
            return {
                ...state,
                schemaData: schemaData
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default schemaReducer;
