import React, { useState } from "react"
// import FileUpload from "react-mui-fileuploader"
import useAuth from 'hooks/useAuth';
import { FormattedMessage } from "react-intl";
import { Button } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

const ImageUpload = ({ onChange, value }) => {
    const { upload_image } = useAuth();
    const [currentFile, setCurrentFile] = useState(undefined);

    const uploadImage = async () => {
        setCurrentFile({ ...currentFile, status: 'uploading' });
        const override_id = !value ? null : value.split('promo_')[1].split('.')[0];
        const publ_url = await upload_image(currentFile.file, override_id);
        console.debug(publ_url);
        if (onChange) {
            onChange(publ_url);
        }
        setCurrentFile({ ...currentFile, status: 'uploaded', url: publ_url });
    }

    const selectFile = (event) => {
        try {
            const preview_url = window.URL.createObjectURL(event.target.files[0])
            // create an image element with that selected file
            var img = new Image();
            img.src = preview_url;
            img.onload = function () {
                setCurrentFile({ file: event.target.files[0], w: img.naturalWidth, h: img.naturalHeight, status: 'ready_for_upoad', preview_url: preview_url });
                // unload it
                window.URL.revokeObjectURL(img.src);
            }
        } catch (error) {
            console.warn(error);
        }
    };

    return (
        <>
            {/* <FileUpload
                multiFile={false}
                onFilesChange={handleFilesChange}
                onContextReady={(context) => { }}
                disabled={false}
                showPlaceholderImage={false}
                title="Kies een plaatje"
                header="sleep hier een plaatje heen"
                leftLabel="or"
                rightLabel="to select files"
                buttonLabel="click here"
                buttonRemoveLabel="Remove all"
                maxFileSize={10}
                maxUploadFiles={1}
                maxFilesContainerHeight={900}
                acceptedType={'image/*'}
                errorSizeMessage={'sorry deze afbeelding is te groot'}
                allowedExtensions={['png', 'jpg', 'jpeg', 'gif']}
                PlaceholderGridProps={{ md: 1 }}
                LabelsGridProps={{ md: 8 }}
                PlaceholderImageDimension={{
                    xs: { width: 128, height: 128 },
                    sm: { width: 128, height: 128 },
                    md: { width: 164, height: 164 },
                    lg: { width: 256, height: 256 }
                }}
            /> */}
            {!!value && <img width="80%" src={value} alt="current" />}
            <Button variant="outlined" component="label">
                <FormattedMessage id={'forms.labels.select_an_image_file'} />
                <input hidden accept="image/*" multiple type="file" onChange={selectFile} />
            </Button>
            {!!currentFile && !!currentFile.preview_url && (
                <div>
                    <p>NEW image preview</p>
                    <img width="100%" src={currentFile.preview_url} alt="preview" />
                </div>
            )}
            {/* <pre>{JSON.stringify(currentFile, null, 2)}</pre> */}
            {currentFile && <>
                <p>gekozen bestand</p>
                <ul>
                    <li>name: {currentFile.file.name}</li>
                    <li>w x h: {currentFile.w} x {currentFile.h} </li>
                    <li>size: {Math.trunc(currentFile.file.size / 1024)} kB</li>
                    <li>status: {currentFile.status}</li>
                    <li>url: {currentFile.url}</li>
                </ul>

                <LoadingButton variant="contained" color={'primary'} loading={currentFile.status === 'uploading'} onClick={uploadImage}>Upload image</LoadingButton>
            </>}
        </>
    )
}

export default ImageUpload;
