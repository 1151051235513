// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';

// action - admin reducer
export const SET_RT_VISITS_DATA = '@admin/SET_RT_VISITS_DATA';

export const SET_ITEMS_FOR_RESOURCE = '@admin/SET_ITEMS_FOR_RESOURCE';
export const ADD_ITEM_TO_RESOURCE = '@admin/ADD_ITEM_TO_RESOURCE';
// export const UPDATE_ITEM_IN_RESOURCE = '@admin/UPDATE_ITEM_IN_RESOURCE';
export const LOADING_ADMIN_FIELD = '@admin/LOADING_ADMIN_FIELD';
export const SEARCH_AND_ORDER = '@admin/SEARCH_AND_ORDER';
export const LOADING_DATA = '@admin/LOADING_DATA';
export const SET_ACTIVE_ITEM = '@admin/SET_ACTIVE_ITEM';
export const SET_SELECTED_ITEM = '@admin/SET_SELECTED_ITEM';
export const GET_BY_ID = '@admin/GET_BY_ID';
export const RESET_ITEMS_FOR_RESOURCE = '@admin/RESET_ITEMS_FOR_RESOURCE';
export const UPDATE_ITEM_FOR_RESOURCE = '@admin/UPDATE_ITEM_FOR_RESOURCE';

// action to store schema data (from swagger)
export const SET_SCHEMA_DATA = '@stat/SET_SCHEMA_DATA';

// action -- chart reducer
export const SET_CHART_DATA = '@stat/SET_CHART_DATA';
export const SET_ZOOM_LEVELS = '@stat/SET_ZOOM_LEVELS';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';


export const SET_MENU_RIGHT = '@customization/SET_MENU_RIGHT';
export const MENU_TOGGLE_RIGHT = '@customization/MENU_TOGGLE_RIGHT';
export const MENU_OPEN_RIGHT = '@customization/MENU_OPEN_RIGHT';

export const ADD_MENU_ITEM = '@customization/ADD_MENU_ITEM';

export const PRESET_COLORS = '@customization/PRESET_COLORS';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_OUTLINED_FILLED = '@customization/SET_OUTLINED_FILLED';

// action - snackbar
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';
export const DESTROY_SESSION = 'DESTROY_SESSION';

// action - storage
export const SET_LOCALE = '@storage/locale';
export const SET_STAT_OPTION = '@storage/SET_STAT_OPTION'