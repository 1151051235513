import React from 'react';
import { Autocomplete, Typography, FormControl, FormControlLabel, InputLabel, TextField, Select, Checkbox, MenuItem, Button } from '@mui/material';
import { useIntl, FormattedMessage, } from 'react-intl';
import { useSelector } from 'react-redux';

import ImageUpload from './imageUpload';
// render ONE field
const FormField = ({ config, onChange }) => {
  const DEBUG = process.env.NODE_ENV === 'development';
  const intl = useIntl();
  const adminItems = useSelector((state) => state.admin);
  const empty_option = !config.required &&  ['location','device'].includes(config.field) ? [{id:null, name: 'all'}] : [];
  const raw_options = empty_option.concat( !!adminItems[`${config.resource}Filtered`] ? adminItems[`${config.resource}Filtered`] : adminItems[config.resource]);
 
  // default selected value should be handled outsite the component for consistent state management
  // const selected_value = !!adminItems[`${config.resource}Selected`] ? adminItems[`${config.resource}Selected`] : null;

  // if (!config.value && !!selected_value && !!selected_value.id) {
  //   config.value = selected_value.id;
  //   // onChange(selected_value); // beware of endless loops 
  // }
  const get_option_label = (option, config) => {
    let lbl = option[config.label_attr];
    if (!!option.location && !!option.location.name) lbl += ` (${option.location.name})`;
    else if (!!option.organisation && !!option.organisation.name) lbl += ` [${option.organisation.name}]`;
    
    // translate of possible
    const tr_lbl = intl.formatMessage({ id: `forms.${config.field}.${lbl}` });
    if( tr_lbl !== `forms.${config.field}.${lbl}` ) return tr_lbl;
    return lbl;
  };
  switch (config.type) {
    case 'autocomplete':

      if (!!raw_options && raw_options.length > 0) return (
        <FormControl fullWidth>
          <Autocomplete
            disablePortal
            disabled={!!config.read_only}
            autoSelect={true}
            includeInputInList
            id={config.field}
            name={config.field}
            required={config.required}
            value={!!raw_options ? raw_options.find(option => option[config.key_attr] == config.value) : ''} /* eslint-disable-line eqeqeq */
            // options={raw_options.map( (item) => {
            //   let lbl = `${item[config.label_attr]}`;
            //   if( !!item.location && !!item.location.name ){
            //     lbl += ` (${item.location.name})`;
            //   } 
            //   return { label: lbl, id: item[config.key_attr] } 
            // })}
            options={raw_options}
            isOptionEqualToValue={(option, value) => {
              if (!!value && !!value.id) {
                return option[config.key_attr] == value.id; /* eslint-disable-line eqeqeq */
              }
              return option == value; /* eslint-disable-line eqeqeq */
            }}
            // onChange={onChange}
            onChange={(event, newValue) => { onChange(newValue) }}
            renderInput={(params) => <TextField key={params.id} {...params} label={<FormattedMessage id={`forms.labels.${config.resource}`} />} />}

            getOptionLabel={(option) => get_option_label(option, config)}

            renderOption={(props, option, { inputValue }) => {
              const regex = new RegExp(inputValue, "ig");
              const option_label = get_option_label(option, config);
              const parts = !!inputValue && inputValue.length > 2 ? option_label.split(regex) : [option_label];
              return (
                <li {...props} key={`${option.id}-${option.name}`}>
                  <div>
                    {parts.map((part, index) => (
                      <span>
                        <span key={index}>
                          {part}
                        </span>
                        {index < parts.length - 1 && <span key={index + 100} style={{ fontWeight: 700 }}>
                          {inputValue}
                        </span>}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }} />
          {DEBUG && <pre>{JSON.stringify(config.value)}</pre>}
        </FormControl>);
      if (!!adminItems[config.resource] && adminItems[config.resource].length === 1) return (<span>{adminItems[config.resource][0]}</span>)
      else return (<></>);

    case 'select':
      return (
        <FormControl fullWidth>
          {/* {JSON.stringify(config)} */}

          <InputLabel htmlFor={config.field} required={config.required}>
            <FormattedMessage id={`forms.labels.${config.label}`} />
          </InputLabel>
          <Select
            id={config.field}
            name={config.field}
            value={config.value}
            required={config.required}
            label={<FormattedMessage id={`forms.labels.${config.label}`} />}
            onChange={onChange}
            disabled={!!config.read_only}
          >
            {config.enum.map((option) => (
              <MenuItem key={option} value={option}>
                {/* TODO should we prefix also by resource ? */}
                <FormattedMessage id={`forms.${config.field}.${option}`} />
              </MenuItem>
            ))}
          </Select>
          {DEBUG && <pre>{config.value}</pre>}
        </FormControl>);
    case 'xselect':  // we may alway use auto complete also for select 
      return (
        <FormControl fullWidth>
          <Autocomplete
            disablePortal
            id={config.field}
            required={config.required}
            name={config.field}
            onChange={onChange}

            options={config.enum}
            getOptionLabel={(option) => intl.formatMessage({ id: `forms.${config.field}.${option}` })}
            renderOption={(props, option) => (
              <li {...props}>
                <FormattedMessage id={`forms.${config.field}.${option}`} />
              </li>)}
            renderInput={(params) => <TextField key={params.id} {...params} label={<FormattedMessage id={`forms.labels.${config.label}`} />} />}
          />
          {DEBUG && <pre>{config.value}</pre>}
        </FormControl>);

    case 'boolean':
      return (
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                // labelId={config.label} // ??
                id={config.field}
                name={config.field}
                // defaultChecked={!!config.value || false}
                checked={!!config.value || false}
                // label={`${config.label}${config.required ? ' *' : ''}`}
                // label={<FormattedMessage id={`forms.labels.${config.label}`} />{config.required && <sup> *</sup>}}
                onChange={onChange}
                color="primary"
              />
            }
            label={
              <Typography variant="subtitle1">
                <FormattedMessage id={`forms.labels.${config.label}`} />
              </Typography>
            }
            required={config.required}
            disabled={!!config.read_only}
          />
          {DEBUG && <pre>{config.value}</pre>}
        </FormControl>);
    // case 'nested':
    //   return (
    //       <InputLabel label={config.field}> 
    //         <FormattedMessage id={`forms.labels.${config.label}`} />
    //         <FormFields config={config.children || []} onChange={onChange} />
    //       </InputLabel>
    //   );
    case 'upload_image':
      return (<FormControl fullWidth>
        {/* <pre>{JSON.stringify(config, null, 2)}</pre> */}
        <ImageUpload onChange={onChange} value={config.value} />
      </FormControl>)
    // NOTE: components are NOT functions we can call recursively!
    // case 'array':
    //   // TODO: implement array of string / numbers input type
    //   // simular to https://github.com/redgeoff/mson-react/blob/main/src/fields/list-field.js
    //   return (<FormControl fullWidth>
    //     <pre>{JSON.stringify(config, null, 2)}</pre>
    //     <hr />
    //     {config.subfields.map(sub_field => <FormField key={sub_field.field} config={sub_field} onChange={(newValue) => onChange(newValue, sub_field)} />)}
    //     <hr />
    //     <Button>add one more</Button>
    //   </FormControl>);
    default:
      return (
        <FormControl fullWidth>
          <TextField style={{ ...config.style }}
            type={config.type}
            name={config.field}
            multiline={config.type === "textarea"}
            rows={5}
            InputLabelProps={{
              shrink: true, // config.type === 'date' ? true : false, // else label will be over the date when not focussed
            }}
            label={<FormattedMessage id={`forms.labels.${config.label}`} />}
            // defaultValue={config.value}
            value={config.value}
            onChange={onChange}
            required={config.required}
            disabled={!!config.read_only}
          />
          {DEBUG && <pre>{config.value}</pre>}
        </FormControl>
      );
  }
}

// const FormFields = ({config, onChange}) => {
//   return config.map( (c) => (
//       !c.readOnly && <Grid item xs={12}>
//           <FormField config={c} onChange={onChange} />
//         </Grid>
//       ))
// };

export default FormField;