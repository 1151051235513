import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import { Checkbox } from "@mui/material";

const get_row_value = (attr_name, row) => {
    // extract the raw row value first
    if (!attr_name) {
        console.warn("missing attr name to get from row", row);
        return null;
    }
    let raw_val;
    if (attr_name.includes('.')) {
        const attr_name_parts = attr_name.split('.');
        const rowObj = row[attr_name_parts[0]];
        if (!!rowObj && !!rowObj[attr_name_parts[1]]) {
            raw_val = rowObj[attr_name_parts[1]];
        } else {
            raw_val = '';
        }
    } else {
        raw_val = row[attr_name];
    };
    return raw_val;
}


const transform_to_type = (attr_name, row, type) => {
    // extract the raw row value first
    const raw_val = get_row_value(attr_name, row);

    // use the type to the correct (lang dependend) formating
    switch (type) {
        case 'date':
            return <FormattedDate value={raw_val} year="numeric" month="short" day="numeric" weekday="short" />;
        case 'datetime':
            return <><FormattedDate value={raw_val} year="numeric" month="short" day="numeric" weekday="short" /> <FormattedTime value={raw_val} /></>;
        case 'formvalue':
            return <FormattedMessage id={`forms.${attr_name}.${raw_val}`} />;
        case 'ipaddress':
            return !!raw_val ? <a href={`http://${raw_val}`} target="_blank" rel="noreferrer">{`http://${raw_val}`}</a> : 'onbekend'
        case 'boolean':
            // return <input type="checkbox" checked={!!raw_val} readOnly={true} disabled></input>
            return <Checkbox checked={!!raw_val} readOnly={true} disabled={true}/>
        default:
            return raw_val;
    }
};

export { transform_to_type };