import { lazy } from 'react';


// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';

const NotFoundPage = Loadable(lazy(() => import('views/pages/not-found')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = [{
    path: '/',
    element: <MinimalLayout />,
    children: []
},
{
    path: '*',
    element: <NotFoundPage />
}];

export default AuthenticationRoutes;
