// material-ui
import {
    ListItemButton,
    ListItemIcon,
    ListItemText, Link, Typography, Stack
} from '@mui/material';

import { IconHelp } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import config from 'config';
import { useSelector } from 'react-redux';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //


const AuthFooter = () => {
    const customization = useSelector((state) => state.customization);
    const schema = useSelector((state) => state.schema.schemaData);

    return <>
        <ListItemButton
            sx={{ borderRadius: `${customization.borderRadius}px`, my: 2 }}
            component="a"
            target="_blank"
            href='https://help.itrack.nl/'
        >
            <ListItemIcon>
                <IconHelp stroke={1.5} size="1.3rem" />
            </ListItemIcon>
            <ListItemText primary={<Typography variant="body2">
                <span style={{ textTransform: 'capitalize' }}><FormattedMessage id="help" /></span>
            </Typography>} />
        </ListItemButton>
        <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle2" component={Link} href="https://www.ekzbenelux.com/" target="_blank" underline="hover">
                &copy; :EKZ Benelux // {config.version.replace('itrck', '')} / {!!schema && !!schema.info && schema.info.version.replace('core ', '')}
            </Typography>
        </Stack>
    </>
};

export default AuthFooter;
