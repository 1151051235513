import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import PasswordResetRoutes from "./PasswordResetRoutes";
import AuthenticationRoutes from './AuthenticationRoutes';
import APIDocsRoutes from './APIDocsRoutes';
import OccpancyRoutes from './OccpancyRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        MainRoutes, 
        LoginRoutes, 
        PasswordResetRoutes,
        AuthenticationRoutes, 
        APIDocsRoutes,
        OccpancyRoutes
    ]);
}
