// project imports
import Occupancy from "ui-component/stats/Occupancy";

// ==============================||PUBLIC OCCUOANCY ROUTING ||============================== //

const Routes = {
    path: '/occupancy',
    children: [
    {  
        path: '/occupancy/traficlight/:loc_id',
        element: <Occupancy show_light={true} show_rate={false}/>,
    },
    {  
        path: '/occupancy/traficlight_horz/:loc_id',
        element: <Occupancy show_light={true} show_rate={false} horizontal={true}/>,
    },
    {  
        path: '/occupancy/percentage/:loc_id',
        element: <Occupancy show_light={false} show_rate={true}/>,
    },
    {  
        path: '/occupancy/level/:loc_id',
        element: <Occupancy show_light={false} show_level={true}/>,
    },
    {  
        path: '/occupancy/test/:loc_id',
        element: <Occupancy show_light={true} show_level={true} show_rate={true}/>,
    }
    ]
};

export default Routes;
