// material-ui

import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";

import DynamicForm from '../forms/dynamicForm';
// import UserForm from '../forms/userForm'; // OBSOLETE

// ==============================|| GENERAL DIALOG ||============================== //

const OCC_MODES = [
    'traficlight',
    'traficlight_horz',
    'level',
    'percentage'
];

const TableDialog = ({ open, onClose, type, item, hidden, action }) => {

    const [occMode, setOccMode] = useState('traficlight');
    
    const handleClose = (data) => {
        onClose('closing', data);
        // TODO: add the item to the local list
    };

    const onModeChange = (ev) => {
        setOccMode(ev.target.value);
    }
    
    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    <FormattedMessage id={action} />
                    <span>  </span>
                    <FormattedMessage id={type} />
                    <Typography marginTop="0.7em">
                        <FormattedMessage id={`forms.explainers.${type}.general`} defaultMessage="&nbsp;" />
                    </Typography>
                    <Typography marginTop="0.7em">
                        <FormattedMessage id={`forms.explainers.${type}.${action}`} defaultMessage="&nbsp;" />
                    </Typography>
                    {type === 'location' && !!item?.id && item?.optimal_balance > 0 && <div>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="occ_mode" required={true}>
                            <FormattedMessage id="forms.labels.occ_mode" />
                        </InputLabel>
                        <Select
                            id="occ_mode"
                            name="occ_mode"
                            value={occMode}
                            required={true}
                            label={<FormattedMessage id="forms.labels.occ_mode" />}
                            onChange={onModeChange}
                            disabled={false}
                        >
                            {OCC_MODES.map((option) => (
                            <MenuItem key={option} value={option}>
                                {/* TODO should we prefix also by resource ? */}
                                <FormattedMessage id={`forms.occ_mode.${option}`} />
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                        <pre style={{fontSize: '10px'}}>
                            {`<iframe src="https://${window.location.host}/occupancy/${occMode}/${item?.id}" width="65px" height="65px" style="border:none"/>`}
                        </pre>
                        <div style={{display: 'flex', justifyContent: 'start'}}>
                        <span style={{margin: '12px'}}>preview: </span> <iframe src={`https://${window.location.host}/occupancy/${occMode}/${item?.id}`} width="65px" height="65px" style={{border: '1px #3333 solid'}}/>  
                        </div>  
                    </div>}
                </DialogTitle>
                
                <DialogContent>
                    {type === 'token' && !item
                        ? <FormattedMessage id="create-api-token-in-account_settings" />
                        : <DynamicForm
                            resource={type}
                            initialState={item}
                            hidden={hidden}
                            action={action}
                            onHandleClose={handleClose} />
                    }
                </DialogContent>
            </Dialog>
        </>
    );
};

export default TableDialog;
