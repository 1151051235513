
import RealTimeMetric from "ui-component/stats/RealTimeMetric";

// ==============================|| TABLE -  Metric live icon ||============================== //

const RealTimeMiniMetric = ({ type, dev_or_loc, metric, offset, icon, icon_pos, as = 'icon' }) => {
    return (
        <RealTimeMetric type={type} dev_or_loc={dev_or_loc} metric={metric} offset={offset} icon={icon} icon_pos={icon_pos} as={as}></RealTimeMetric>
    );
};

export default RealTimeMiniMetric;
