import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import adminReducer from './adminReducer';
import accountReducer from './accountReducer';
import storageReducer from './storageReducer';
import schemaReducer from './schemaReducer';

import { DESTROY_SESSION } from './actions';

// ==============================|| COMBINE REDUCER ||============================== //

// Combine all reducers.
const appReducer = combineReducers({
    account: accountReducer,
    snackbar: snackbarReducer,
    admin: adminReducer,
    customization: customizationReducer,
    schema: schemaReducer, 
    storage: persistReducer(
        {
            key: 'storage',
            storage,
            keyPrefix: 'itrack-'
        },
        storageReducer),
    });

 const rootReducer = (state, action) => {   
    // Clear all data in redux store to initial.
    if (action.type === DESTROY_SESSION) {
        state = { account: state.account, schema: state.schema }; // we asume the user is logged out here
        // state = { schema: state.schema }; // empty account raises errors ...     
           
    }
    return appReducer(state, action);
 };

export default rootReducer;
